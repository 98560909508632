<template>
  <b-modal
    :id="modalId"
    ref="executiveAddRowModal"
    centered
    no-close-on-backdrop
    title="Add Risk / Opps"
    hide-footer
    hide-header-close
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refExecutiveFormObserver"
    >
      <!-- Form -->
      <b-form
        id="executive-add-row-modal-form"
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <!-- Type -->
        <validation-provider
          #default="validationContext"
          name="Type"
          rules="required"
        >
          <b-form-group
            label="Type"
            label-for="Type"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="newRowData.type"
              :options="typeOptions"
              :reduce="val => val.value"
              input-id="executive-type"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Description -->
        <validation-provider
          #default="validationContext"
          name="Description"
          rules="required"
        >
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="newRowData.description"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2 justify-content-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Add
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, getCurrentInstance, inject, toRefs } from 'vue'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    bpu: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    const { bpu } = toRefs(props)
    const modalId = ref(`executive-add-row-modal-${bpu.value}`)
    const typeOptions = ref([
      {"label": "Risk", "value": "Risk"},
      {"label": "Opps", "value": "Opps"}
    ])
    const vm = getCurrentInstance().proxy

    const executiveData = inject("executiveData")

    const blankFiltersData = {
      type: 'Risk',
      description: ''
    }

    const newRowData = ref(JSON.parse(JSON.stringify(blankFiltersData)))
    const resetRowData = () => {
      newRowData.value = JSON.parse(JSON.stringify(blankFiltersData))
    }


    const checkDuplicate = () => {
      let isDuplicate = false
      for(let data of executiveData.value) {
        if ((data.year.toUpperCase().includes('RISK') 
          || data.year.toUpperCase().includes('OPP')) 
          && data.bpu === newRowData.value.description) {
            isDuplicate = true
        }
      }

      return isDuplicate
    }

    const onSubmit = () => {
      if(checkDuplicate()) {
        vm.$swal({
          title: 'Duplicado!',
          text: `Ya existe un "${newRowData.value.type}" con la descripción "${newRowData.value.description}"`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        emit('add-new-row', newRowData.value)
        resetForm()
        vm.$bvModal.hide(modalId.value)
      }
    }

    const onCancel = () => {
      resetForm()
      vm.$bvModal.hide(modalId.value)
    }

    const {
      refExecutiveFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRowData)

    return {
      modalId,
      newRowData,
      onSubmit,
      onCancel,

      typeOptions,

      refExecutiveFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
