<template>
  <div>
    <b-tabs
      pills
    >
      <!-- Tab: Info -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="DatabaseIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">SUMMARY EXECUTIVE S&OP</span>
        </template>
        
        <executive-visual-1></executive-visual-1>
      </b-tab>

      <!-- Tab: PowerBi -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="DatabaseIcon"
            size="18"
            class="mr-50"
          />
          <span class="d-none d-sm-inline">EXECUTIVE POWERBI</span>
        </template>

        <executive-power-bi></executive-power-bi>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { 
  BRow, BCol,
  BButton, BTab, BTabs,
 } from 'bootstrap-vue'
import ExecutivePowerBi from './ExecutivePowerBi.vue'
import ExecutiveVisual1 from './visual1/ExecutiveVisual1.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab, 
    BTabs,

    ExecutivePowerBi,
    ExecutiveVisual1,
  },
  data() {
    return {

    }
  },
}
</script>

<style>
.tab-content > .tab-pane:not(.active),
.pill-content > .pill-pane:not(.active) {
  display: block !important;
  height: 0;
  overflow-y: hidden;
} 
</style>
