<template>
  <b-card>
    <iframe title="Executive PowerBi" 
    width="100%" height="1000" 
    src="https://app.powerbi.com/view?r=eyJrIjoiZGViNzM2MWQtYjBhMy00MDJkLTgxOGQtNTY4YWY4ZTAyODQ4IiwidCI6IjgxNzBiNWViLTZhZTctNGU1MC05NWI3LWExOGJhNTgzODc2OSIsImMiOjR9" 
    frameborder="0" allowFullScreen="true"></iframe>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,

  },

}
</script>

<style>

</style>