<template>
  <b-card
  >
    <div style="width: 150px">
      <b-form-group
        label="Año"
        label-for="Año"
      >
        <v-select
          v-model="yearSelected"
          :options="yearOptions"
          :reduce="val => val.value"
          input-id="executive-vselect-year"
          :clearable='clearableSelect'
          @input="changedSelectedYear"
        />
      </b-form-group>
    </div>
    
    <executive-visual-bpu-total></executive-visual-bpu-total>
    
    <b-tabs
      class="executive-visual-1-tabs"
      pills
    >
      <b-tab
        v-for="(bpu, index) in bpuValues"
        :key="index"
        :active="index === 0"
      >
        <template #title>
          <feather-icon
            icon="DatabaseIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{bpu}}</span>
        </template>
        
        <executive-visual-bpu :bpuSelected="bpu"></executive-visual-bpu>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { 
  BCard, BTab, BTabs, BFormGroup,
} from 'bootstrap-vue'
import { ref, getCurrentInstance, provide, computed } from 'vue'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import vSelect from 'vue-select'
import ExecutiveVisualBpu from './ExecutiveVisualBpu.vue'
import ExecutiveVisualBpuTotal from './ExecutiveVisualBpuTotal.vue'

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BFormGroup,

    ExecutiveVisualBpu,
    ExecutiveVisualBpuTotal,
    CustomLoading,
    vSelect,
  },
  setup(props) {
    const clearableSelect = ref(false)
    const yearSelected = ref()
    const yearOptions = ref()
    const isLoading = ref(true)

    // provide
    provide("yearSelected", yearSelected)
    provide("isLoading", isLoading)

    const bpuValues = ref([
      "Cosmetics",
      "Derma",
      "Health Care",
    ])

    const changedSelectedYear = () => {
      isLoading.value = true
    }

    const getCurrentYear = () => {
      return new Date().getFullYear()
    }

    const setYearOptions = () => {
      let currentYear = getCurrentYear()
      yearSelected.value = currentYear
      let nextYear = currentYear + 1
      yearOptions.value = [
        {"label": currentYear, "value": currentYear},
        {"label": nextYear, "value": nextYear}
      ]
    }

    setYearOptions()

    return {
      clearableSelect,
      yearSelected,
      yearOptions,
      bpuValues,

      changedSelectedYear,
    }
  },
}
</script>

<style lang="scss">
.tab-content > .tab-pane:not(.active),
.pill-content > .pill-pane:not(.active) {
  display: block !important;
  height: 0;
  overflow-y: hidden;
}

.executive-visual-1-tabs {
  .nav.nav-pills {
    li a {
      height: 49px;
      font-size: 20px;
    }
  }
}

</style>
